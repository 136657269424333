import { Injectable } from '@angular/core';
import {Notification, OnSiteMessageEventData} from '../interfaces';
import { ToastrService } from 'ngx-toastr';
import {AnalyticsService} from "./analytics.service";
import {AnalyticsEvent} from "../enums";

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private readonly toastrService: ToastrService, private readonly analyticsService: AnalyticsService,) {}

  public showNotification(
    notification: Notification,
    context =  ''
  ): void {
    const activeToast = this.toastrService.show(
      notification.message,
      notification.title,
      {
        timeOut: 3000,
        disableTimeOut: false,
        positionClass: 'toast-top-center',
      },
      `toast-${notification.type}`
    );

    if (notification.action !== null) {
      activeToast.onAction.subscribe(() => {
        notification.action!();
      });
    }

    this.sendOnSiteMessageEvent(notification, context);
  }

  private sendOnSiteMessageEvent(notification: Notification, context: string):void{
    const onSiteMessageEventData: OnSiteMessageEventData = {
      message_type: notification.type,
      message_text: notification.message,
      context
    }
    this.analyticsService.trackEvent(AnalyticsEvent.ON_SITE_MESSAGE_VIEWED, onSiteMessageEventData);
  }

}