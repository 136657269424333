import { FormEventTrackingService } from 'src/app/core/services/form-event-tracking.service';
import { Directive, HostListener, Input } from '@angular/core'
import {AnalyticsEvent, AnalyticsService, FormErrorEventData, FormFieldBaseEventData, FormFieldCompletedEventData} from "../../core";
import { AbstractControl } from '@angular/forms';

@Directive({
    selector: '[trackFormField]'
})
export class TrackFormFieldDirective {
    @Input() formId = '';
    @Input() fieldName = '';
    @Input() control: AbstractControl | null = null;

    constructor(private formTrackingService: FormEventTrackingService, private _analytics: AnalyticsService) {}
    @HostListener('focus')
    onFocus() {
        const fieldStatus = this.formTrackingService.getFieldStatus(this.formId, this.fieldName);
        
        if (!fieldStatus?.started) {
            this.formTrackingService.updateFieldStatus(this.formId, this.fieldName, { started: true });
            const eventObj: FormFieldBaseEventData = {
                event: AnalyticsEvent.FIELD_STARTED,
                field: `${this.fieldName}`,
            };
            this._analytics.trackEvent(AnalyticsEvent.FIELD_STARTED, eventObj);
        }
    }

    @HostListener('blur')
    onBlur() {
      const fieldStatus = this.formTrackingService.getFieldStatus(this.formId, this.fieldName);

      if (this.control?.dirty && this.control?.valid && !fieldStatus?.completed) {
        this.formTrackingService.updateFieldStatus(this.formId, this.fieldName, { completed: true });
        const eventObj: FormFieldCompletedEventData = {
          event: AnalyticsEvent.FIELD_COMPLETED,
          field: `${this.fieldName}`,
          context: `${this.formId}`,
        };
        this._analytics.trackEvent(AnalyticsEvent.FIELD_COMPLETED, eventObj);
      } else if (this.control?.dirty && this.control?.invalid) {
        this.formTrackingService.updateFieldStatus(this.formId, this.fieldName, { completed: false });
      }

      if (this.control?.touched && this.control?.invalid && !fieldStatus?.hasError) {
        this.formTrackingService.updateFieldStatus(this.formId, this.fieldName, { hasError: true });
        const errorMessage = this.formTrackingService.getErrorMessage(this.control, this.fieldName);
        const eventObj: FormErrorEventData = {
          event: AnalyticsEvent.FORM_ERROR,
          field: `${this.fieldName}`,
          error_message: `${errorMessage}`,
          error_message_id: '',
          context: `${this.formId}`,
        };
        this._analytics.trackEvent(AnalyticsEvent.FORM_ERROR, eventObj);
      } else if (this.control?.dirty && this.control?.valid) {
        this.formTrackingService.updateFieldStatus(this.formId, this.fieldName, { hasError: false });
      }
    }
}