import {Component, OnDestroy, OnInit} from '@angular/core';
import {AnalyticsEvent, AnalyticsService, RedeemProvider, RedeemRequest, RedeemStatus} from "../../../../../core";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ModalService} from "../../../../services";
import {UserInfoService} from "../../../../../core/store";
import {RedeemSummaryComponent} from "../../../redeem-cancel-summary/redeem-summary.component";

@Component({
    selector: 'app-redeem-cards',
    templateUrl: './redeem-cards.component.html',
    styleUrls: ['./redeem-cards.component.scss']
})
export class RedeemCardsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    public redeemRequests: RedeemRequest[] = [];

    constructor(
        private readonly redeemProvider: RedeemProvider,
        private readonly modalService: ModalService,
        private readonly userInfoService: UserInfoService,
        private readonly analyticsService: AnalyticsService,
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    cancel(request: RedeemRequest) {
        this.redeemProvider.deletePlayerRedeem(request.id).pipe(
            takeUntil(this.destroy$),
        ).subscribe({
            next: () => {
                const dialogRef = this.modalService.open(RedeemSummaryComponent);

                dialogRef.componentInstance.request = request;
                dialogRef.componentInstance.isSuccess = true;

                this.refresh();
                this.userInfoService.reloadBalances();
                this.sendAnalyticsCancelRedeem(request);

            },
            error: (response) => {
                const dialogRef = this.modalService.open(RedeemSummaryComponent);

                dialogRef.componentInstance.isSuccess = false;
                dialogRef.componentInstance.message = response?.error?.detail ?? response
            }
        })
    }

    private sendAnalyticsCancelRedeem(request: RedeemRequest) {
        this.analyticsService.trackEvent(AnalyticsEvent.REDEEM_CANCELED, {
            redeem_amount: request.amount,
            source: 'cta'
        })
    }

    refresh() {
        this.redeemProvider.playerRedeems().pipe(
            takeUntil(this.destroy$),
        ).subscribe((requests) => {
            this.redeemRequests = requests.filter(r => r.status === RedeemStatus.Requested);
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}