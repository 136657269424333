import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {LobbyGame} from "../../../core/interfaces/lobby";
import {AnalyticsEvent, AnalyticsService, GamesProvider} from "../../../core";

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit, AfterViewInit {
  @ViewChild('search', {static: true}) search!: ElementRef;
  @Input() dropdownPosition!: any;
  public searchValue: string = "";
  public isOpen: boolean = false;
  public games: LobbyGame[] = []

  constructor(
      private readonly gamesProvider: GamesProvider,
      private readonly analyticsService: AnalyticsService,
  ) {
  }

  public ngOnInit(): void {
    //this.search.nativeElement.focus();
  }

  ngAfterViewInit() {
    fromEvent(this.search.nativeElement,'keyup')
        .pipe(
            debounceTime(800),
            distinctUntilChanged(),
        )
        .subscribe(async () => {
          this.searchValue = this.search.nativeElement.value;

          if (this.searchValue.length < 2) {
            this.isOpen = false;
            this.games = [];

            return;
          }

          this.games = await this.gamesProvider.getGamesByCriteria(this.searchValue.toLowerCase()).toPromise() as LobbyGame[];
          this.isOpen = true;
          this.sendAnalyticsEventForSearchTerm(this.searchValue, this.games.length);
        });
  }

  private sendAnalyticsEventForSearchTerm(searchTerm: string, gamesLength: number): void {
    if(searchTerm.length < 3) return;
    this.analyticsService.trackEvent(AnalyticsEvent.SEARCH_SUBMITTED, {
      search_term: searchTerm,
      search_result: gamesLength > 0
    })

  }

  clear() {
    this.searchValue = ""
    this.isOpen = false
  }
}