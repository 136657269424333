<div class="modal">
    <div class="modal__header">
        <h1>Redeem Coins</h1>
        <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>

    <div class="modal__content">
        <ng-container *ngIf="!showIframe">
            <div class="redeem-coins">
                <div class="redeem-coins__logo">
                    <img src="../../../../assets/images/LuckySweep-Logo.svg" alt="Caisno Logo" >
                </div>
                <div class="redeem-coins__header">
                    Redeem Your Coins
                </div>
                <div class="redeem-coins__details" [class.loader-component]="redeemInProgress || loadingBalance">
                    <div class="mb-32">
                        <span>SC must meet the Minimum Redemption amount of 100SC.</span>
                    </div>
                    <div class="mb-8">Total SC: {{ totalBalance | number }}</div>
                    <div class="mb-25">Locked SC: {{ lockedBalance | number }}</div>
                    <div class="mb-32">Your redeemable balance is</div>
                    <div class="balance-accent">SC {{ balance | number }}</div>
                </div>
                <div class="redeem-coins__body">
                    <form *ngIf="form" [formGroup]="form">
                        <ng-container *ngLet="form.get('amount') as formInput">
                            <mat-form-field
                                    *ngIf="formInput"
                                    appearance="outline"
                                    class="material-block material-block__input"
                                    hideRequiredMarker="true"
                                    floatLabel="always"
                            >
                                <mat-label>Enter the amount you want to redeem</mat-label>
                                <input matInput formControlName="amount" mask="separator.2" thousandSeparator=","
                                       type="text" placeholder="Ex. 120" trackFormField fieldName="amount" [formId]="formId" [control]="form.get('amount')">
                                <mat-icon class="icon-gray" matSuffix>
                                    {{ formInput.invalid ? 'error' : 'check_circle' }}
                                </mat-icon>
                                <mat-error *ngIf="formInput.invalid && formInput.touched && getErrorMessage(formInput)">
                                    {{ getErrorMessage(formInput) }}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!isPNMActive">
                            <ng-container *ngIf="bankAccounts.length > 0 && !useBankDetails">
                                <ng-container *ngLet="form.get('account') as formInput">
                                    <mat-form-field
                                            *ngIf="formInput"
                                            appearance="outline"
                                            class="material-block material-block__select material-block__select-gray"
                                            hideRequiredMarker="true"
                                    >
                                        <mat-label>Select a bank account</mat-label>
                                        <mat-select formControlName="account" trackFormField fieldName="account" [formId]="formId" [control]="form.get('account')">
                                            <mat-option *ngFor="let account of bankAccounts" [value]="account.upoId">
                                                {{ account.upoBankName }} {{ account.upoName }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                                *ngIf="formInput.invalid && formInput.touched">{{ getErrorMessage(formInput) }}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </form>
                    <div *ngIf="isPNMActive" [class.loader-component]="loaderAP">
                        <div class="redeem-coins__footer">
                            <ng-container *ngIf="!showCardMethods && !showBancAccountMethods; else paymentMethods">
                                <button
                                        class="btn btn-large btn-secondary  btn-block"
                                        (click)="showCardMethods = true;checkIsActiveMethodLoaded();"
                                        [disabled]="redeemInProgress || !hasAmount">
                                    <span>Redeem on card</span>
                                </button>
                                <button
                                        class="btn btn-large btn-secondary  btn-block"
                                        (click)="showBancAccountMethods = true;checkIsActiveMethodLoaded();"
                                        [disabled]="redeemInProgress || !hasAmount">
                                    <span>Redeem on Bank Account</span>
                                </button>
                            </ng-container>
                            <ng-template #paymentMethods>
                                <ng-container *ngIf="showCardMethods">
                                    <div class="message-wrapper" *ngIf="activeCardPaymentMethods.length === 0;">
                                        <p>No active payment methods</p>
                                    </div>
                                    <ng-container *ngIf="activeCardPaymentMethods.length > 0;">
                                        <div class="payment-method-container" *ngFor="let method of activeCardPaymentMethods">
                                            <div class="payment-method" (click)="redeemPNM(method.paymentMethodIdentifier, RedeemType.PayNearMeCard)">
                                                <div class="payment-method__number">
                                                    **** **** **** {{ method.number }}
                                                </div>
                                                <div class="payment-method__bottom">
                                                    <div>{{ method.expirationDate }}</div>
                                                    <div>{{ method.cardBrand }}</div>
                                                </div>
                                            </div>
                                            <div class="payment-method__delete" 
                                                (click)="deleteMethod(method)">
                                                <mat-icon class="test" matTooltip="Delete">delete</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <button class="btn btn-block btn-large"
                                        (click)="addNewMethod(RedeemTypeVar.PayNearMeCard)">
                                        Add new card
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="showBancAccountMethods">
                                    <div class="message-wrapper" *ngIf="activeBankAccountPaymentMethods.length === 0;">
                                        <p>No active payment methods</p>
                                    </div>
                                    <ng-container *ngIf="activeBankAccountPaymentMethods.length > 0;">
                                        <div class="payment-method-container" *ngFor="let method of activeBankAccountPaymentMethods">
                                            <div class="payment-method" (click)="redeemPNM(method.paymentMethodIdentifier, RedeemType.PayNearMe)">
                                                <div>{{ method.description }}</div>
                                                <div class="payment-method__number">**** **** **** {{ method.number }}</div>
                                            </div>
                                            <div class="payment-method__delete" 
                                                (click)="deleteMethod(method)">
                                                <mat-icon matTooltip="Delete">delete</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <button class="btn btn-block btn-large"
                                        (click)="addNewMethod(RedeemTypeVar.PayNearMe)">
                                        Add new account
                                    </button>
                                </ng-container>
                            </ng-template>
                        </div>
                    </div>
                    <ng-container *ngIf="!isPNMActive">
                        <div class="redeem-coins__actions">
                            <div class="action-button"
                                 [class.disabled]="redeemInProgress || !hasAmount"
                                 (click)="redeem(RedeemType.NuveiCard)">
                                <i class="u_card-atm"></i>
                                <span>Redeem on card</span>
                            </div>
                            <div *ngIf="!useBankDetails" class="action-button"
                                 [class.disabled]="!hasAmount"
                                 (click)="linkNewAccount()">
                                <i class="u_plus-circle"></i>
                                <span>Link new bank account</span>
                            </div>
                            <div *ngIf="!details" class="action-button" (click)="addBankDetails()">
                                <i class="u_plus-circle"></i>
                                <span>Add Bank Details</span>
                            </div>
                            <div *ngIf="details" class="action-button"
                                 [class.disabled]="!hasAmount"
                                 (click)="hasAmount ? applyBankDetails() : null">
                                <i [ngClass]="!useBankDetails ? 'u_plus-circle' : 'u_minus-circle'"></i>
                                <span>{{ !useBankDetails ? "Use Bank Details" : "Don't use Bank Details" }}</span>
                            </div>
                        </div>
                        <div class="redeem-coins__footer">
                            <p *ngIf="isFormValid" class="info">
                                {{ useBankDetails ? 'Redeem via Bank Details.' : 'Redeem via Linked Bank Account.' }}
                            </p>
                            <button
                                    class="btn btn-large btn-block"
                                    (click)="redeem()"
                                    [disabled]="redeemInProgress || !isFormValid"
                                    [class.loading]="redeemInProgress">
                                <span>Redeem Coins</span>
                                <i class="u_arrow-circle-right" aria-describedby="Arrow right"></i>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="showIframe">
            <iframe *ngIf="iframeUrl !== null" [src]="iframeUrl"></iframe>
        </ng-container>
    </div>
</div>