import { AbstractControl, FormGroup } from '@angular/forms';
import { FormFieldStatus } from '../../shared/interfaces/form-field-status';
import { Injectable } from '@angular/core';
import { AnalyticsService } from './analytics.service';

@Injectable({
    providedIn: 'root',
})
export class FormEventTrackingService {
    private fieldStatuses = new Map<string, Map<string, FormFieldStatus>>();

    constructor(private _analytics: AnalyticsService) {}

    public initializeFormStatus(formId: string, form: FormGroup) {
      let formStatus = this.fieldStatuses.get(formId);
      if (!formStatus) {
          formStatus = new Map();
          this.fieldStatuses.set(formId, formStatus);
      }

      Object.keys(form.controls).forEach(fieldName => {
        if (formStatus && !formStatus.has(fieldName)) {
            formStatus.set(fieldName, { started: false, completed: false, hasError: false });
        }
      });

    }

    public getFieldStatus(formId: string, fieldName: string): FormFieldStatus | undefined {
        return this.fieldStatuses.get(formId)?.get(fieldName);
    }

    public updateFieldStatus(formId: string, fieldName: string, updates: Partial<FormFieldStatus>) {
        const fieldStatus = this.fieldStatuses.get(formId)?.get(fieldName);
        if (fieldStatus) {
          Object.assign(fieldStatus, updates);
        }
    }

    public getErrorMessage(control: AbstractControl<any, any>, fieldName: string): string {
      if (control.hasError('required'))
        return 'This field is required.';

      if (control.hasError('notEnoughYears'))
          return 'You must be at least 18 years old.'
      if (control.hasError('notEnoughDigits'))
          return 'Please use a year higher than 1900.'

      if (control.hasError('notContainTenDigits'))
          return 'Your phone number should be exactly ten digits.'

      if (control.hasError('notContainFiveDigits'))
          return 'Invalid ZIP code. Please enter a 5-digit number.'

      if (control.hasError('has8Letters'))
          return 'Password must contain at least: 8 letters.'

      if (control.hasError('hasUpperCase'))
          return 'Password must contain at least: 1 Uppercase.'

      if (control.hasError('hasLowerCase'))
          return 'Password must contain at least: 1 Lowercase.'

      if (control.hasError('hasDigits') || control.hasError('hasNumber'))
          return 'Password must contain at least: 1 Numeric.'

      if (control.hasError('hasSpecial') || control.hasError('hasSpecialCharacters'))
          return 'Password must contain at least: 1 Symbol or special.'

      if (control.hasError('isOldPassword'))
          return "Password must contain at least: New password shouldn't be the same as old."

      if (control.hasError('isNotSameAsNewPassword'))
          return "Password must contain at least: New password and confirm password must be the same."
      
      if (fieldName === 'email' && control.hasError('pattern'))
        return "That doesn't look like a correct email address."
    
      if (fieldName === 'username' && control.hasError('maxlength') && control.hasError('minlength'))
        return "Length should be between 3 and 12 symbols."
    
      if (fieldName === 'amount' && control.hasError('min'))
        return "Value should be at least 100SC."
    
      if (fieldName === 'amount' && control.hasError('max'))
        return "Value can't be more than your balance."
      
      if (control.hasError('NoPassswordMatch'))
          return "Your passwords don't match."

      if (control.hasError('maxlength'))
          return "Max length error."
      
      if (control.hasError('minlength'))
          return "Min length error."

      if (control.hasError('notContainTenDigits'))
          return "Not contain ten digits."

      if (control.hasError('pattern'))
          return "Pattern error."

      return ""
    }
}