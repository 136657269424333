import {Component, EventEmitter, Output} from '@angular/core';
import {
    BankAccountTypeLabel,
    BankDetails,
    BankDetailsProvider,
    NavigationService,
    NotificationService
} from "../../../../core";
import {BankDetailsQuery, BankDetailsService} from "../../../../core/store/bank-details";

@Component({
    selector: 'app-bank-details-confirmation',
    templateUrl: './bank-details-confirmation.component.html',
    styleUrls: ['./bank-details-confirmation.component.scss']
})
export class BankDetailsConfirmationComponent {
    @Output() onSubmit: EventEmitter<boolean> = new EventEmitter();
    @Output() onBack: EventEmitter<boolean> = new EventEmitter();

    public bankDetails: BankDetails | null = null;
    public inProgress = false;

    constructor(
        private _nav: NavigationService,
        private bankDetailsQuery: BankDetailsQuery,
        private readonly bankDetailsService: BankDetailsService,
        private readonly bankDetailsProvider: BankDetailsProvider,
        private readonly notificationService: NotificationService,
    ) {
        this.bankDetailsQuery.bankDetails$.subscribe((details) => {
            this.bankDetails = details;
        })
    }

    public get accountTypeText(): string {
        if (this.bankDetails === null)
            return '';

        return BankAccountTypeLabel.get(this.bankDetails?.accountType) ?? ""
    }

    public save() {
        if (this.bankDetails === null)
            return;
        this.inProgress = true;
        this.bankDetailsProvider.saveBankDetails(this.bankDetails).subscribe({
            next: () => {
                this.bankDetailsService.clear();
                this.onSubmit.emit()
            },
            error: (error) => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: error.error.detail
                }, 'Save Bank Details')
            },
        });
    }

    public back() {
        this.onBack.emit()
    }
}