export enum AnalyticsEvent {
    SIGN_UP_FORM = 'sign_up_form',
    SIGN_UP_COMPLETED = 'sign_up_completed',
    KYC_STARTED = 'kyc_started',
    KYC_FINISHED = 'kyc_finished',
    COIN_STORE_OPENED = 'coin_store_opened',
    COIN_STORE_CLOSED = 'coin_store_closed',
    CHECKOUT_STARTED = 'checkout_started',
    COIN_PACKAGE_VIEWED = 'coin_package_viewed',
    COIN_PACKAGE_SELECTED = 'coin_package_selected',
    PURCHASE_COMPLETED = 'purchase_completed',
    PURCHASE_FAILED = 'purchase_failed',
    FORM_ERROR = 'form_error',
    FIELD_STARTED = 'field_started',
    FIELD_COMPLETED = 'field_completed',
    PROFILE_FORM = 'profile_form',
    PROFILE_COMPLETED = 'profile_completed',
    CURRENCY_CHANGED = 'currency_changed',
    LOGIN_FORM = 'login_form',
    LOGIN_COMPLETED = 'login_completed',
    LOGIN_ATTEMPT_FAILED = 'login_attempt_failed',
    GAME_CLICKED = 'game_clicked',
    GAME_VIEWED = 'game_viewed',
    GAME_LAUNCHED = 'game_launched',
    GAME_CLOSED = 'game_closed',
    SHOW_ALL_GAMES_CLICKED = 'show_all_games_clicked',
    COLLECTION_DISPLAYED = 'collection_displayed',
    GAME_IN_FULL_SCREEN = 'game_in_full_screen',
    GAME_SAVED_AS_FAVORITE = 'game_saved_as_favorite',
    REDEEM_COINS_STARTED = 'redeem_coins_started',
    REDEEM_REQUESTED = 'redeem_requested',
    REDEEM_CANCELED = 'redeem_canceled',
    SEARCH_SUBMITTED = 'search_submitted',
    ON_SITE_MESSAGE_VIEWED = 'on_site_message_viewed'
}