import {Injectable, OnDestroy} from "@angular/core";
import * as moment from "moment";
import {ActivityCheckInfoComponent, TimeLimitReachedComponent} from "src/app/shared/modals";
import {CheckPlatformService, ModalService} from "src/app/shared/services";
import {PlayersProvider} from "src/app/core/providers";
import {ResponsibleGamblingQuery, ResponsibleGamblingStore, timersRG} from "src/app/core/store/resposible-gambling";
import {PlayerLimitation, SelfExclusionRequest, TimeLimitRequest} from "src/app/core/interfaces";
import {RestrictionPeriodType, RestrictionPeriodTypeLabel, RestrictionType} from "src/app/core/enums";
import {EnumHelper, NotificationService} from "src/app/core";
import {filter, map, take, takeUntil, tap, withLatestFrom} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {AuthService} from "../auth";

@Injectable({providedIn: 'root'})
export class ResponsibleGamblingService implements OnDestroy {
    private destroy$ = new Subject<boolean>();

    private selfExclusionPeriodList = EnumHelper.GetObject(RestrictionPeriodType, RestrictionPeriodTypeLabel);
    private isDailyTimerActive = false;
    private isActivityTimerActive = false;
    private dailyLimit = 0;
    private dailyInterval: any;
    private activityTimeout: any;

    constructor(
        private readonly rgStore: ResponsibleGamblingStore,
        private readonly rgQuery: ResponsibleGamblingQuery,
        private readonly playerProvider: PlayersProvider,
        private readonly modalService: ModalService,
        private readonly platformService: CheckPlatformService,
        private readonly notificationService: NotificationService,
        private readonly authService: AuthService,
    ) {
        this.rgQuery.timerStarted$.subscribe((item: timersRG) => {
            this.isDailyTimerActive = item.dailyTimer;
            this.isActivityTimerActive = item.activityTimer;
        })
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    public getLimitations(): Observable<PlayerLimitation | null> {
        return this.rgQuery.limitation$.pipe(
            withLatestFrom(
                this.rgQuery.limitationLoaded$,
                this.rgQuery.limitationIsLoading$
            ),
            tap(([_, loaded, loading]) => {
                if (!loaded && !loading) {
                    this.loadLimitations();
                }
            }),
            map(([details, loaded]) => {
                return loaded ? details : null;
            })
        )
    }

    public loadLimitations(): void {
        this.authService.isAuthenticated().pipe(
            takeUntil(this.destroy$),
        ).subscribe((isAuthenticated) => {
            if (isAuthenticated) {
                this.rgStore.setLimitationLoading(true);

                this.playerProvider.getPlayerLimitations().subscribe((limitationData) => {
                    this.rgStore.setLimitation(limitationData);
                })
            }
        })
    }

    public reloadLimitations(): void {
        this.rgStore.setLimitationLoaded(false);
        this.loadLimitations();
    }

    public setRGDailyLimit(limit: string): void {
        this.rgStore.setDailyLimit(limit);
    }

    public setRGDailyLimitValue(limit: string): void {
        this.rgStore.setDailyLimitValue(limit);
    }

    public setRGSpendTime(limit: string): void {
        this.rgStore.setSpendTime(limit);
    }

    public spendInterval = (startTimestamp: moment.Moment, spendTime: string) => {
        this.updateTimers({
            dailyTimer: true,
        });

        this.dailyInterval = setInterval(() => {
            startTimestamp = moment(startTimestamp)
            startTimestamp.add(1, 'minute');
            this.setRGSpendTime(this.convertToTime(startTimestamp))
            spendTime = this.convertToTime(startTimestamp);
            if (this.convertToDate('spendTime') >= this.convertToDate('dailyLimit')) {
                const entity: TimeLimitRequest = {
                    dailyRestrictionReached: new Date().toISOString(),
                    dailyTimeLimit: null
                }
                this.playerProvider.addPlayerTimeLimit(entity).subscribe(res => {
                    clearInterval(this.dailyInterval);

                    const dialogRef = this.modalService.open(TimeLimitReachedComponent);

                    dialogRef.componentInstance.limit = this.dailyLimit;
                });
            }
        }, this.convertToSeconds(1));
        return this.dailyInterval;
    }

    public selectTimeLimit(limit: number): void {
        if (!this.platformService.checkIfIsPlatformBrowser()) return;
        let startTimestamp = this.rgQuery.getValue().dailyLimitValue
            ? moment(this.rgQuery.getValue().dailyLimitValue) : this.getStartOfDay();
        let spendTime = this.convertToTime(startTimestamp);
        if (spendTime !== null && this.convertToDate('spendTime') > new Date(spendTime)) {
            startTimestamp = moment(spendTime);
        }
        this.setRGDailyLimitValue(limit.toString());

        if (!this.isDailyTimerActive) {
            this.spendInterval(startTimestamp, spendTime);
        }

        this.dailyLimit = limit;
        if (limit == 0) {
            this.clear();
        } else {
            this.clear();
            const dailyLimit = this.getStartOfDay();
            dailyLimit.add(this.dailyLimit, 'hours');
            this.setRGSpendTime(this.convertToTime(startTimestamp));
            this.setRGDailyLimit(this.convertToTime(dailyLimit));
        }
    }

    public clear(): void {
        clearInterval(this.dailyInterval);
        clearInterval(this.activityTimeout);
        this.rgStore.clear();
    }

    public async setSelfExclusion(period: number): Promise<void> {
        const entity: SelfExclusionRequest = {
            type: RestrictionType.SelfExclusion,
            period: this.selfExclusionPeriodList[period].value,
            isActive: true
        }
        await this.playerProvider.addSelfExclusion(entity).pipe(
            take(1),
            tap((limit: PlayerLimitation) => {
                if (!limit) return;
                if (!limit.restrictionEnd) return;

                const limitPeriod = limit.period == RestrictionPeriodType.Custom
                    ? RestrictionPeriodType.Custom.toString()
                    : new Date(limit.restrictionEnd).toLocaleString()

                sessionStorage.setItem('RestrictionEndDate', limitPeriod);

            }, err => {
                const msg = err.error.detail;
                this.notificationService.showNotification({
                    type: 'error',
                    message: msg
                }, 'Responsible gaming - Set Self Exclusion');
            })).toPromise();
    }

    public updateTimers(timers: any) {
        this.rgStore.setTimer({
            dailyTimer: this.isDailyTimerActive,
            activityTimer: this.isActivityTimerActive,
            ...timers
        });
    }

    public updateActivityCheck(activityValue: number | null): Observable<any> {
        return this.playerProvider.updateActivityCheck({
            activityCheck: activityValue
        }).pipe(
            tap(() => {
                if (!activityValue) {
                    this.updateTimers({
                        activityTimer: false
                    });
                    clearInterval(this.activityTimeout);
                } else {
                    this.startActivityCheckTimeOut(activityValue);
                }
            })
        )
    }

    public resetActivityCheck() {
        this.getLimitations().pipe(
            filter(r => r !== null),
            take(1)
        ).subscribe((data) => {
            this.updateTimers({
                activityTimer: false
            });

            clearInterval(this.activityTimeout);

            this.startActivityCheckTimeOut(data?.activityCheck ?? 0);
        })
    }

    public initActivityCheck(): void {
        this.getLimitations().pipe(
            filter(r => r !== null),
            take(1)
        ).subscribe((limitationData) => {
            if (!limitationData?.activityCheck) return;

            this.startActivityCheckTimeOut(limitationData?.activityCheck)
        })
    }

    private startActivityCheckTimeOut(delay: number): void {
        if (!this.platformService.checkIfIsPlatformBrowser()) return;

        this.updateTimers({
            activityTimer: true
        });

        this.activityTimeout = setTimeout(() => {
            this.modalService.open(ActivityCheckInfoComponent);
        }, this.convertToSeconds(delay))
    }

    private destroyResponsibleGamblingStore(): void {
        this.rgStore.destroy();
    }

    private isEmptyObject(obj: Object): boolean {
        return Object.keys(obj).length === 0;
    }

    private getStartOfDay(): moment.Moment {
        return moment().startOf('day');
    }

    private getHours(s: string): number {
        return new Date(s).getHours();
    }

    private convertToDate(localStorageKay: 'spendTime' | 'dailyLimit'): Date {
        return new Date(localStorage.getItem(localStorageKay)!);
    }

    private convertToSeconds(minutes: number): number {
        return minutes * 60000;
    }

    private convertToTime(time: moment.Moment | string): string {
        return moment(time).format('YYYY-MM-DD HH:mm:ss');
    }
}