import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {
    CoinPackageOffer,
    CoinPackageProvider,
    GtmEvent,
    GtmService,
    AnalyticsEvent,
    AnalyticsService,
    NotificationService
} from "../../../../../core";
import {isColorLight} from "../../../../utils";
import {ClaimModalComponent} from "../../../claim-modal/claim-modal.component";
import {CoinPackageService} from "../../../../../core/services/coin-package.service";
import {ModalService} from "../../../../services";
import {UserInfoService} from "../../../../../core/store";
import {isNumber} from "@datorama/akita";

@Component({
    selector: 'app-coin-card',
    templateUrl: './coin-card.component.html',
    styleUrls: ['./coin-card.component.scss']
})
export class CoinCardComponent implements OnInit, OnChanges {
    @Input()
    public coin: CoinPackageOffer | null = null;

    @Input()
    public coinPositionIndex: number | null = null;

    @Input()
    public hideButton: boolean = false;

    @Input()
    public disabledButton: boolean = false;

    @Input()
    public hideIcon: boolean = false;

    public countdown: string = '';
    public isPurchase: boolean = false;
    public isClaim: boolean = false;
    public hasDiscount: boolean = false;
    public inProgress: boolean = false;

    private updateCountdownInterval: any;
    private wasInViewportOnce: boolean = false;

    constructor(
        private readonly coinPackageService: CoinPackageService,
        private readonly gtmService: GtmService,
        private readonly analyticsService: AnalyticsService,
        private readonly coinPackageProvider: CoinPackageProvider,
        private readonly userInfoService: UserInfoService,
        private readonly modalService: ModalService,
        private readonly notificationService: NotificationService,
    ) {
    }

    get isBackgroundLight() {
        return isColorLight(this.coin?.labelColor)
    }

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (!this.coin)
            return;

        const discountPrice = this.coin.discountPrice ?? 0

        this.isClaim = this.coin.price === 0 || this.coin.price === null;
        this.isPurchase = this.coin.price > 0
            || discountPrice > 0
            && this.coin.price > discountPrice;
        this.hasDiscount = this.coin.price > 0
            && discountPrice > 0
            && this.coin.price !== this.coin.discountPrice

        clearInterval(this.updateCountdownInterval);

        if (this.coin.limitedTimeOffer === 1) {
            this.updateCountdown();

            // Countdown updated every second (1000ms)
            this.updateCountdownInterval = setInterval(() => {
                this.updateCountdown()
            }, 1000);
        }
    }

    select(): void {
        if (!this.coin || this.disabledButton)
            return;

        const coinPosition = isNumber(this.coinPositionIndex) ? this.coinPositionIndex + 1 : 0

        if (this.isClaim) {
            this.inProgress = true;
            this.gtmService.pushEvent(GtmEvent.ClaimingFreeCoinPackage)

            this.coinPackageProvider.claimCoin(this.coin.id).subscribe(result => {

                const dialogRef = this.modalService.open(ClaimModalComponent);

                dialogRef.componentInstance.coinPackage = this.coin

                this.userInfoService.reloadBalances();
                this.inProgress = false;

            }, error => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: error.error.detail
                }, 'Claim Coin');
                throw error;
            })
        }

        if (this.isPurchase) {
            this.gtmService.pushEvent(GtmEvent.BuyingCoinPackage);

            this.coinPackageService.openPurchaseModal(this.coin.id, coinPosition);
        }

        const eventData = this.analyticsService.mapCoinPackageEventData(this.coin, coinPosition)
        this.analyticsService.trackEvent(AnalyticsEvent.COIN_PACKAGE_SELECTED, eventData)

    }

    private updateCountdown(): void {
        if (!this.coin!.expirationTime)
            return;

        const now = new Date();
        const expirationTime = new Date(this.coin!.expirationTime);

        if (expirationTime > now) {
            const timeDifference = expirationTime.getTime() - now.getTime();

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

            this.countdown = `${days}d ${hours}h ${minutes}m`;
        } else {
            this.countdown = 'Expired';
        }
    }

    onInViewport(isInViewport: boolean): void {
        if (isInViewport && !this.wasInViewportOnce && this.coin) {
            this.wasInViewportOnce = true;
            // Track event only if coinPositionIndex is presented
            if(isNumber(this.coinPositionIndex)){
                const eventData = this.analyticsService.mapCoinPackageEventData(this.coin, this.coinPositionIndex + 1)
                this.analyticsService.trackEvent(AnalyticsEvent.COIN_PACKAGE_VIEWED, eventData)
            }
        }
    }

}