import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {CheckoutStartedEventData} from "../../interfaces";

const createInitialState = (): AnalyticsStateEntity => {
  return {
    // buyingCoinPackage is a temporary object that will be used to store the data of the coin package that the user is buying
    buyingCoinPackage: null
  };
}

export interface AnalyticsStateEntity {
  buyingCoinPackage: CheckoutStartedEventData | null
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'analytics'})
export class AnalyticsStore extends Store <AnalyticsStateEntity> {
  constructor() {
    super(createInitialState());
  }

  public setBuyingCoinPackage(buyingCoinPackage: CheckoutStartedEventData): void {
    this.update({
      buyingCoinPackage: buyingCoinPackage,
    })
  }

  public clear(): void {
    this.update(createInitialState());
  }
}