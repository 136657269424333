import { Injectable, OnDestroy } from '@angular/core';
import {
  CoinStoreComponent,
  EditDetailsModalComponent,
  PurchaseCoinsComponent,
  RedeemCoinsComponent,
} from '../../shared/modals';
import { CoinArea, RiskStatus } from '../interfaces';
import { KycService } from './kyc.service';
import {
  AccountService,
  AccountQuery,
  UserInfoQuery,
  UserInfoService,
} from '../store';
import { NotificationService } from './notification.service';
import { CoinAreaType, KycStatus, PlayerStatus } from '../enums';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { NavigationService } from './navigation.service';
import { Observable, Subject, combineLatest } from 'rxjs';
import { ModalService } from '../../shared/services';

@Injectable({ providedIn: 'root' })
export class CoinPackageService implements OnDestroy {
  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly kycService: KycService,
    private readonly userInfoQuery: UserInfoQuery,
    private readonly userInfoService: UserInfoService,
    private readonly _notification: NotificationService,
    private readonly navigationService: NavigationService,
    private readonly accountService: AccountService,
    private readonly modalService: ModalService,
    private readonly notificationService: NotificationService,
    private readonly accountQuery: AccountQuery,
  ) {}

  public isStoreModalReady(): Observable<boolean> {
    return combineLatest([
      this.userInfoQuery.userLoaded$,
      this.accountQuery.detailsLoaded$,
    ]).pipe(
      map(([userInfoLoaded, accServiceDetailsLoaded]) => {
        return userInfoLoaded && accServiceDetailsLoaded;
      })
    );
  }

  public openCoinStoreModal(): void {
    this.userInfoService
      .getUser()
      .pipe(take(1), withLatestFrom(this.accountService.hasDetails()))
      .subscribe(([user, hasDetails]) => {
        if (user === null) {
          this.navigationService.navigateTo('./auth/sign-in');
          return;
        }

        if (user?.status === PlayerStatus.Restricted) {
          this.notificationService.showNotification({
            type: 'error',
            message: 'Please contact Support team',
          }, 'Open coin store - player restricted');

          return;
        }

        if (user?.status === PlayerStatus.Active && user.riskStatus === RiskStatus.Restricted && user.kycStatus === KycStatus.Verified) {
          this.navigationService.navigateTo('/kyc/error');
          
          return;
        }

        if (!hasDetails) {
          this.modalService.open(EditDetailsModalComponent);
        } else {
          this.modalService.open(CoinStoreComponent);
        }
      });
  }

  public openPurchaseModal(id: number, coinPosition: number): void {
    this.kycService.checkPurchase().then(() => {
      const dialogRef = this.modalService.open(PurchaseCoinsComponent);

      dialogRef.componentInstance.coinPackageId = id;
      dialogRef.componentInstance.coinGridPosition = coinPosition;
    });
  }

  public openRedeemModal(): void {
    this.kycService.checkRedeem().then(() => {
      this.modalService.open(RedeemCoinsComponent);
    });
  }

  public showReceiveNotification(coinArea: CoinArea): void {
    this.userInfoQuery.hasCoinArea$.pipe(take(1)).subscribe(hasCoinArea => {
      if (hasCoinArea) return;

      this._notification.showNotification({
        type: 'success',
        message: this.receiveMessage(coinArea),
      }, 'Coin package - On player adjustment');

      this.userInfoService.setHasCoinArea(true);
    });
  }

  private receiveMessage(coinArea: CoinArea): string {
    if (!coinArea) return '';
    switch (coinArea.area) {
      case CoinAreaType.FBSignup: {
        return `Congratulations! \n You have received ${this.getBalance(coinArea)} for social signing up!`;
      }
      case CoinAreaType.Login: {
        return `Congratulations! \n You have received ${this.getBalance(coinArea)} for logging in!`;
      }
      case CoinAreaType.Signup: {
        return `Congratulations! \n You have received ${this.getBalance(coinArea)} for signing up!`;
      }
    }
  }

  private getBalance(coinArea: CoinArea): string {
    switch (true) {
      case !!coinArea.sweepCoin && !!!coinArea.goldCoin: {
        return `${coinArea.sweepCoin} SC`;
      }
      case !!!coinArea.sweepCoin && !!coinArea.goldCoin: {
        return `${coinArea.goldCoin} LC`;
      }
      case !!coinArea.sweepCoin && !!coinArea.goldCoin: {
        return `${coinArea.goldCoin} LC & ${coinArea.sweepCoin} SC`;
      }
      default: {
        return '';
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$?.next(true);
    this.destroy$?.complete();
  }
}