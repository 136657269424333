import {Injectable} from "@angular/core";
import {GamesStore} from "./games.store";
import {GamesProvider} from "../../providers";
import {GameItem, GameItemResponse, SessionResponse} from "../../interfaces";
import {filter, map, switchMap, take, tap, withLatestFrom} from "rxjs/operators";
import {combineLatest, Observable, of} from "rxjs";
import {UserInfoService} from "../user";
import {GamesQuery} from "./games.query";
import {GameType} from "../../enums";
import {LobbyGame} from "../../interfaces/lobby";
import {NavigationService, NotificationService} from "../../services";
import {environment} from "../../../../environments/environment";

@Injectable({providedIn: 'root'})
export class GamesService {
    constructor(
        private readonly gamesQuery: GamesQuery,
        private readonly gamesStore: GamesStore,
        private readonly gamesProvider: GamesProvider,
        private readonly userInfoService: UserInfoService,
        public readonly navigationService: NavigationService,
        private readonly notificationService: NotificationService,
    ) {
    }

    public getMyGames(): Observable<GameItemResponse> {
        return combineLatest([
            this.gamesQuery.myGames$,
            this.userInfoService.getUser(),

        ]).pipe(
            withLatestFrom(
                this.gamesQuery.myGamesLoaded$,
                this.gamesQuery.myGamesIsLoading$,
            ),
            filter(([[_, user]]) => user !== null),
            tap(([[_, user], loaded, loading]) => {
                if (!loaded && !loading) {
                    this.loadMyGames(user!.id);
                }
            }),
            map(([[games]]) => {
                return games;
            })
        )
    }

    public getRecentGames(): Observable<LobbyGame[]> {
        return this.getMyGames().pipe(
            map((myGames) => {
                return myGames.recentGames
            }),
            switchMap((recentGames) => {
                if (recentGames.length === 0) {
                    return of([])
                }

                const myGamesIdentifiers = recentGames.map(item => item.gameId);
                return this.gamesProvider.getGamesByIdentifiers(myGamesIdentifiers);
            })
        );
    }

    public getFavoriteGames(): Observable<LobbyGame[]> {
        return this.getMyGames().pipe(
            map((myGames) => {
                return myGames.favoriteGames
            }),
            switchMap((games) => {
                if (games.length === 0) {
                    return of([])
                }

                const myGamesIdentifiers = games.map(item => item.gameId);
                return this.gamesProvider.getGamesByIdentifiers(myGamesIdentifiers);
            })
        );
    }

    public loadMyGames(userId: number): void {
        this.gamesStore.setLoading(true)

        this.gamesProvider.getMyGames(userId).subscribe((response) => {
            this.gamesStore.setMyGames(response)
        })
    }

    public addGame(gameIdentifier: string, type: GameType): void {
        this.gamesProvider.getGamesByIdentifiers([gameIdentifier]).pipe(
            withLatestFrom(this.userInfoService.getUser())
        ).subscribe(([games, user]) => {
            const game = games[0];
            const currentUser = user;
            if (game === undefined || currentUser === null) {
                return;
            }
            let newGame = {
                gameId: game.Code,
                userId: currentUser.id,
                gameType: type,
                gameName: game.DisplayTitle
            } as GameItem;
            this.gamesProvider.addGame(newGame).subscribe(() => {
                this.clear();
            })
        });
    }

    public removeGame(gameIdentifier: string): void {
        this.userInfoService.getUser().subscribe((user) => {
            if (user === null) return;

            this.gamesProvider.removeGame(gameIdentifier, user.id).subscribe(() => {
                this.clear();
            })
        });
    }

    public clear(): void {
        this.gamesStore.clear();
    }

    public joinGame(game: LobbyGame): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (environment.FEATURES_SETTINGS.WALLET !== 'true' || environment.FEATURES_SETTINGS.RESPONSIBLE_GAMBLING !== 'true') {
                resolve("");
                return;
            }

            let subscription = !game.IsFree ? this.gamesProvider.launchGame(game) : this.gamesProvider.launchGameDemo(game);

            subscription
                .pipe(
                    take(1)
                )
                .subscribe(
                    (res: SessionResponse) => {
                        const gameUrl = res!.launch_options!.game_url;
                        this.addGame(game.Code, GameType.Last);

                        resolve(gameUrl);
                    },
                    err => {
                        let message = err.message;
                        if (err?.error?.detail) {
                            try {
                                message = JSON.parse(err.error.detail);
                            } catch (e) {
                                message = err.error.detail
                            }
                        }

                        this.notificationService.showNotification({
                            type: 'error',
                            message: message.message
                        }, 'Game launching');

                        reject(message.message);
                    }
                );
        });
    }
}