import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    AdvanceBannerContent,
    BannerAction,
    BannerActionSize,
    BannerActionStyle,
    BannerActionType,
    BannerAlignment,
    BannerBlocks,
    BannerContent,
    BannerProvider,
    BasicBannerContent,
    Breakpoint,
    NavigationService,
    UserInfo,
} from "../../../core";
import {takeUntil, tap, withLatestFrom} from "rxjs/operators";
import {Subject} from "rxjs";
import {AuthService, UserInfoService} from "../../../core/store";
import {castTo} from "../../utils";
import {CoinPackageService} from "../../../core/services/coin-package.service";
import {SignInModalComponent} from "../../modals";
import {CheckPlatformService, ModalService} from "../../services";

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() bannerId!: number;
    @Input() slim = false;

    private destroy$ = new Subject<boolean>();
    private autoSlideInterval: any;
    private user!: UserInfo;

    public BannerBlocksTypes = BannerBlocks;
    public isInitial = true;
    public isLoading = true;
    public selectedIndex = 0;
    public bannerImages: (BannerContent | AdvanceBannerContent)[] = [];
    public isMobileView = false;
    public isAuthenticated = false;

    public $simpleImage = castTo<BannerContent>();
    public $advancedImage = castTo<AdvanceBannerContent>();

    constructor(
        private readonly bannersProvider: BannerProvider,
        private readonly navigationService: NavigationService,
        private readonly coinPackageService: CoinPackageService,
        private readonly authService: AuthService,
        private readonly modalService: ModalService,
        private readonly checkPlatformService: CheckPlatformService,
        private readonly userInfoService: UserInfoService,
    ) { }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        const viewPortWidth = window.innerWidth;
        this.isMobileView = viewPortWidth < Breakpoint.phablet;
    }

    ngOnDestroy() {
        clearInterval(this.autoSlideInterval);

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    get selectedBanner(): (BannerContent | AdvanceBannerContent) | null {
        if (this.bannerImages.length === 0)
            return null;

        return this.bannerImages[this.selectedIndex];
    }

    ngOnInit(): void {
        this.onResize();
    }

    ngAfterViewInit () {
        this.userInfoService.getUser().subscribe(user =>  {
            this.user = user ?? {} as UserInfo;
            this.loadBanner(this.user.id);
        });
    }

    private loadBanner(userId?: number): void {
        this.bannersProvider.getBanner(this.bannerId, userId).pipe(
            takeUntil(this.destroy$),
            withLatestFrom(this.authService.isAuthenticated()),
            tap(([banner, isAuthenticated]) => {
                this.isAuthenticated = isAuthenticated;

                [
                    ...banner.Content,
                    ...banner.Items
                ].forEach((item) => {
                    const shouldAdd = !item.Audience
                        || item.Audience === "All"
                        || (isAuthenticated && item.Audience === "Logged")
                        || (!isAuthenticated && item.Audience === "Non-logged");

                    if (shouldAdd)
                        this.bannerImages.push(item)
                })

                if (this.checkPlatformService.checkIfIsPlatformBrowser() && this.bannerImages.length > 1) {
                    this.autoSlideInterval = setInterval(() => {
                        this.nextSlide()
                    }, 7000)
                }
            })
        ).subscribe();
    }

    onImageLoad() {
        this.isLoading = false;
        this.isInitial = false;
    }

    nextSlide() {
        this.isLoading = true;

        let nextIndex = this.selectedIndex + 1;

        if (nextIndex > this.bannerImages.length - 1) {
            nextIndex = 0;
        }

        this.selectedIndex = nextIndex;
    }

    previousSlide() {
        this.isLoading = true;

        let prevIndex = this.selectedIndex - 1;

        if (prevIndex < 0) {
            prevIndex = this.bannerImages.length - 1;
        }

        this.selectedIndex = prevIndex;
    }

    changeIndex(i: number) {
        this.isLoading = true;
        this.selectedIndex = i;
    }

    getCurrentImageSource(image: BasicBannerContent): string {
        return this.isMobileView && image.MobileImage
            ? image.MobileImage.url
            : image.DesktopImage.url;
    }

    getActionButtonClass(action: BannerAction) {
        const buttonClasses = [];

        switch (action.Style) {
            case BannerActionStyle.Secondary:
                buttonClasses.push('btn-secondary')
                break;
            case BannerActionStyle.Outlined:
                buttonClasses.push('btn-outlined')
                break;
            case BannerActionStyle.Blue:
                buttonClasses.push('btn-blue')
                break;
            case BannerActionStyle.Orange:
                buttonClasses.push('btn-orange')
                break;
            case BannerActionStyle.Green:
                buttonClasses.push('btn-green')
                break;
            case BannerActionStyle.Red:
                buttonClasses.push('btn-red')
                break;
            case BannerActionStyle.White:
                buttonClasses.push('btn-white')
                break;
        }

        switch (action.Size) {
            case BannerActionSize.Small:
                buttonClasses.push('btn-small')
                break;
            case BannerActionSize.Large:
                buttonClasses.push('btn-large')
                break;
            case BannerActionSize.ExtraLarge:
                buttonClasses.push('btn-extra-large')
                break;
        }

        return buttonClasses.length > 0 ? buttonClasses.join(' ') : '';
    }

    onActionClick(action: BannerActionType, item: any) {
        switch (action) {
            case BannerActionType.Signup:
                this.navigationService.navigateToWithPreservedQueryParams('./auth/sign-up');
                break;
            case BannerActionType.Purchase:
                if (this.isAuthenticated) {
                    this.coinPackageService.openCoinStoreModal();
                } else {
                    this.modalService.open(SignInModalComponent);
                }
                break;
            case BannerActionType.Link:
            case BannerActionType.External: {
                const url = item.Url || item.ExternalLink;
                this.navigationService.navigateToExternal(url)
                break;
            }
            case BannerActionType.Play:
                if (item.Game) {
                    this.navigationService.navigateTo(`/game/${item.Game.Provider.Slug}/${item.Game.Slug}`)
                } else {
                    this.navigationService.navigateTo('404')
                }
                break;
        }
    }

    getContentAlignmentClass(advancedImage: AdvanceBannerContent) {
        switch (advancedImage.Alignment) {
            case BannerAlignment.Left:
                return 'left-align'
            case BannerAlignment.Center:
                return 'center-align'
            case BannerAlignment.Right:
                return 'right-align'
        }
    }

    getCurrentAspectRatio(advancedImage: AdvanceBannerContent) {
        return this.isMobileView && advancedImage.MobileAspectRatio
            ? advancedImage.MobileAspectRatio
            : advancedImage.DesktopAspectRatio;
    }
}
