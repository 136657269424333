import { Injectable, Renderer2 } from '@angular/core';
import { PaymentsStore } from './payments.store';
import { OrdersProvider, PaymentsProvider } from '../../providers';
import {
  CheckoutOrderRequest,
  CheckoutOrderResponse,
  NuveiIbtOrderResponse,
  NuveiOrderResponse,
  OrderRequest,
  PaymentProvider,
  PayNearMeOrderResponse,
} from '../../interfaces';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaymentsQuery } from './payments.query';
import {
  NavigationService,
  NotificationService,
  ScriptService,
} from '../../services';
import { AccountService } from '../account';
import { environment } from '../../../../environments/environment';
import { JsEventHelper } from '../../helpers';

@Injectable({ providedIn: 'root' })
export class PaymentsService {
  constructor(
    private readonly paymentsQuery: PaymentsQuery,
    private readonly gamesStore: PaymentsStore,
    public readonly navigationService: NavigationService,
    private readonly notificationService: NotificationService,
    private readonly paymentsProvider: PaymentsProvider,
    private readonly ordersProvider: OrdersProvider,
    private readonly accountService: AccountService,
    private readonly scriptService: ScriptService
  ) {}

  public getProviders(): Observable<PaymentProvider[]> {
    return this.paymentsQuery.providers$.pipe(
      withLatestFrom(
        this.paymentsQuery.providersLoaded$,
        this.paymentsQuery.providersIsLoading$
      ),
      tap(([_, loaded, loading]) => {
        if (!loaded && !loading) {
          this.loadProviders();
        }
      }),
      map(([providers]) => {
        return providers;
      })
    );
  }

  public loadProviders(): void {
    this.gamesStore.setLoading(true);

    this.paymentsProvider.getPaymentProviders().subscribe(providers => {
      this.gamesStore.setProviders(providers);
    });
  }

  public loadFlykkScript(renderer: Renderer2) {
    const { SCRIPT_URL } = environment.FLYKK_SETTINGS!;

    this.scriptService.loadJsScript(renderer, SCRIPT_URL, 'flykkScript');
  }

  public loadPayNearMeScript(
    renderer: Renderer2,
    callback: Function | null = null
  ) {
    const { SCRIPT_URL } = environment.PAYNEARME_SETTINGS!;

    this.scriptService.loadJsScript(
      renderer,
      SCRIPT_URL,
      'payNearMeScript',
      () => {
        if (callback) callback();

        document.addEventListener('click', (e: any) => {
          JsEventHelper.OnPseudoElementClick(e, 'pnm_modal', 'before', () => {
            document.getElementById('pnm_close')!.click();
          });
        });
      }
    );
  }

  public initiateFlykkPayment(
    coinPackageId: number,
    isAcquiring: boolean = false
  ): Promise<any> {
    return new Promise<any>(async (resolve, error) => {
      this.accountService
        .getDetails()
        .pipe(
          filter(details => details !== null),
          switchMap(details => {
            return this.ordersProvider.createFlykkOrder({
              coinPackageId: coinPackageId,
              details: details!,
              isAcquiring: isAcquiring,
            });
          })
        )
        .subscribe({
          next: data => {
            resolve(data);
          },
          error: response => {
            this.notificationService.showNotification({
              type: 'error',
              message: response?.error?.detail ?? response,
            }, 'Initiate Flykk Payment');
            error();
          },
        });
    });
  }

  public initiateNuveiPayment(
    coinPackageId: number
  ): Observable<NuveiOrderResponse> {
    return this.ordersProvider.createNuveiOrder({
      coinPackageId: coinPackageId,
    });
  }

  public initiateNuveiIbtPayment(
    coinPackageId: number
  ): Observable<NuveiIbtOrderResponse> {
    return this.ordersProvider.createNuveiIbtOrder({
      coinPackageId: coinPackageId,
    });
  }

  public initiateCheckoutPayment(
    data: CheckoutOrderRequest
  ): Observable<CheckoutOrderResponse> {
    return this.ordersProvider.createCheckoutOrder(data);
  }

  public initiatePayNearMePayment(
    data: OrderRequest
  ): Observable<PayNearMeOrderResponse> {
    return this.ordersProvider.createPayNearMeOrder(data);
  }

  public cancelPayNearMePayment(data: OrderRequest): Observable<void> {
    return this.ordersProvider.cancelPayNearMeOrder(data);
  }

  public clear(): void {
    this.gamesStore.clear();
  }
}