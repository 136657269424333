import {Injectable} from "@angular/core";
import {AnalyticsStore} from "./analytics.store";
import {AnalyticsQuery} from "./analytics.query";
import {CheckoutStartedEventData} from "../../interfaces";
import {take} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class AnalyticsService {
    constructor(
        private readonly analyticsStore: AnalyticsStore,
        private readonly analyticsQuery: AnalyticsQuery
    ) {

    }

    getBuyingCoinPackage(): Observable<CheckoutStartedEventData | null> {
        return this.analyticsQuery.buyingCoinPackage$.pipe(
            take(1)
        );
    }


    setBuyingCoinPackage(buyingCoinPackage: CheckoutStartedEventData): void {
        this.analyticsStore.setBuyingCoinPackage(buyingCoinPackage)
    }

    dispose(): void {
        this.analyticsStore.clear()
    }
}