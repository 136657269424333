import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CoinPackageOffer, NotificationService, PaymentName, PaymentType} from "../../../../core";
import {takeUntil, withLatestFrom} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";
import {EVENT_TYPES} from "../../../constants";
import {TransactionStatus} from "../../../../core/enums/transaction";
import {EventBus} from "../../../../core/infrastructure";
import {PaymentsService} from "../../../../core/store";
import {environment} from "../../../../../environments/environment";

declare var checkout: any;

@Component({
    selector: 'app-nuvei-payment',
    templateUrl: './nuvei-payment.component.html',
    styleUrls: ['./nuvei-payment.component.scss']
})
export class NuveiPaymentComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();
    private completeSubscription$: Subscription | null = null;

    public inProgress = false;
    public hasOverlay = false;

    @Input()
    public coin!: CoinPackageOffer;

    @Input()
    public showButton: boolean = false;

    @Output()
    public onComplete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onClose: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private paymentsService: PaymentsService,
        private eventBus: EventBus,
        private readonly notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.eventBus.OnChange<number>(EVENT_TYPES.PAYMENT_STARTED).pipe(
            takeUntil(this.destroy$),
            withLatestFrom(this.paymentsService.getProviders())
        ).subscribe(([providerId, providers]) => {
            const currentProvider = providers.find(p => p.id === providerId);

            if (currentProvider && currentProvider.name === PaymentName.NUVEI && currentProvider.type === PaymentType.Card) {
                this.initPayment();
            }
        })
    }

    public initPayment() {
        this.inProgress = true;

        this.paymentsService.initiateNuveiPayment(this.coin.id).subscribe({
            next: (data) => {
                this.hasOverlay = true;

                checkout({
                    ...data,
                    renderTo: '#checkout',
                    env: environment.ENVIRONMENT === "production" ? "prod" : "int",
                    pmBlacklist: ['apmgw_Neteller', 'apmgw_QIWI', 'apmgw_expresscheckout', 'apmgw_ApmEmulator', 'apmgw_eCheckSelect'],
                    showCardLogos: true,
                    blockCards: ["unionpay", "maestro", "diners", "amex"],
                    alwaysCollectCvv: "true",
                    maskCvv: true,
                    currency: 'USD',
                    country: 'US',

                    prePayment: (paymentDetails: any) => {
                        const errorMessage = "Only US cards are allowed";
                    
                        return new Promise<void>((resolve, reject) => {
                            const isUSCountry = paymentDetails.paymentOption.card.issuerCountry === "us";
                            const isEmptyCountry = paymentDetails.paymentOption.card.issuerCountry === "";
                    
                            if (environment.ENVIRONMENT === "production" && !isUSCountry) {
                                reject(errorMessage);
                            } 
                            if (environment.ENVIRONMENT !== "production" && !isUSCountry && !isEmptyCountry) {
                                reject(errorMessage);
                            }

                            resolve();
                        });
                    },
                    onResult: (data: any) => {
                        if (data.result === "APPROVED") {
                            this.close();
                            this.onComplete.emit({
                                status: TransactionStatus.Succeeded
                            });
                        }
                        checkout.destroy();
                    }
                });
            }
            ,
            error: (response) => {
                this.notificationService.showNotification({
                    type: "error",
                    message: response?.error?.detail ?? response,
                }, 'Initiate Nuvei Payment');

                this.inProgress = false;

                this.close();
            }
        });

        this.completeSubscription$ = this.eventBus.OnChange(EVENT_TYPES.PURCHASE_COMPLETE).pipe(
            takeUntil(this.destroy$)
        ).subscribe((msg: any) => {
            this.close();

            this.onComplete.emit({
                status: msg.status
            });
        })
    }

    public close() {
        this.inProgress = false;
        this.hasOverlay = false;

        this.onClose.emit();
        this.completeSubscription$?.unsubscribe();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}