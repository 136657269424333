import {Component, HostListener, Input} from '@angular/core';
import {Breakpoint} from "../../../core";
import {LobbyCategory, LobbyCollection, LobbyGame} from "../../../core/interfaces/lobby";

@Component({
    selector: 'app-static-carousel',
    templateUrl: './static-carousel.component.html',
    styleUrls: ['./static-carousel.component.scss']
})
export class StaticCarouselComponent {
    private scrollTimeout: any | null = null;

    @Input() headerTitle: string = "";
    @Input() games: LobbyGame[] = [];
    @Input() vertical: boolean = false;
    @Input() loading: boolean = false;
    @Input() collection: LobbyCollection | null = null;
    @Input() category: LobbyCategory | null = null;

    public canGoPrev: boolean = false;
    public canGoNext: boolean = true;
    public take: number = 0;
    public skip: number = 0;
    public itemsPerPage: number = 0;

    constructor() {
        this.onResize();
    }

    goNext($event: Event) {
        this.scrollTo($event, 'next');
    }

    goPrev($event: Event) {
        this.scrollTo($event, 'prev');
    }

    onScroll($event: Event) {
        if (this.scrollTimeout)
            clearTimeout(this.scrollTimeout);

        this.scrollTimeout = setTimeout(() => {
            const gamesBlock: HTMLElement | null = ($event.target as HTMLElement).closest(".games-block");
            const container: HTMLElement | null = gamesBlock?.querySelector('.container') || null;

            if (container) {
                const gameGapSize = 12;
                const isLast = (container.scrollLeft + container.offsetWidth + gameGapSize) > container.scrollWidth;
                this.canGoNext = !isLast;
            }

            this.canGoPrev = (container?.scrollLeft || 0) > 0;
        }, 100)
    }

    @HostListener('window:resize', ['$event'])
    private onResize(event?: Event): void {
        this.itemsPerPage = 3;

        if (window.innerWidth > Breakpoint.tablet)
            this.itemsPerPage = 4
        if (window.innerWidth > Breakpoint.laptop)
            this.itemsPerPage = 5
        if (window.innerWidth > Breakpoint.desktop)
            this.itemsPerPage = 6
    }

    private scrollTo($event: Event, direction: 'next' | 'prev') {
        const gamesBlock = ($event.target as Element).closest(".games-block");
        const container = gamesBlock?.querySelector('.container');
        const gameItem = gamesBlock?.querySelector('.game-item');

        const gameGapSize = 12;
        const gameItemWidth = gameItem!.clientWidth | 0;
        const scrollWidth = gameItemWidth * this.itemsPerPage + (gameGapSize * this.itemsPerPage);
        const scrollBy = direction == 'next' ? scrollWidth : -scrollWidth;

        container?.scrollBy(scrollBy, 0);
    }
}