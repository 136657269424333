import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AuthService,
  GamesService,
  UserInfoService,
} from '../../../core/store';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {LobbyCategory, LobbyCollection, LobbyGame} from '../../../core/interfaces/lobby';
import {
  AnalyticsEvent,
  AnalyticsService, GameBaseEventData,
  GameClickedEventData, GameEventDataWithBalances,
  GameSource,
  GameType,
  NavigationService
} from '../../../core';
import {
  FreeCoinsModalComponent
} from '../../../shared/modals';
import { ModalService } from '../../../shared/services';

@Component({
  selector: 'app-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
})
export class GameCardComponent implements OnInit {
  private destroy$ = new Subject<boolean>();

  @Input() game: LobbyGame | null = null;
  @Input() showDelete: boolean = false;
  @Input() showFavorite: boolean = true;
  @Input() category: LobbyCategory | null = null;
  @Input() collection: LobbyCollection | null = null;
  @Input() gamePositionIndex: number = 0;
  @Input() source: GameSource = 'direct';

  @Output() onLaunch: EventEmitter<void> = new EventEmitter();

  public imgUrl: string = `https://cdn.softswiss.net/i/s4/softswiss`;
  private GAME_IMAGE_PLACEHOLDER =
    'https://game-assets.rootz.com/game-thumbs/desktop/standard/18289.jpg';
  public isFavourite: boolean = false;
  public isImageLoaded: boolean = false;
  public isAuthenticated: boolean = false;
  private wasInViewportOnce: boolean = false;

  constructor(
    private readonly gamesService: GamesService,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService,
    private readonly userInfoService: UserInfoService,
    private readonly modalService: ModalService,
    private readonly analyticsService: AnalyticsService
  ) {}

  public get gameUrl(): string {
    return this.game !== null
      ? `/games/${this.game.Provider.Slug}/${this.game.Slug}/play`
      : '';
  }

  ngOnInit(): void {
    this.authService
      .isAuthenticated()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
      });

    this.gamesService
      .getMyGames()
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe(myGames => {
        const filtered = myGames.favoriteGames.filter(
          game => game.gameId === this.game?.Code
        );
        this.isFavourite = filtered.length > 0;
      });
  }

  public errorHandler(event: any): void {
    event.target.src = this.GAME_IMAGE_PLACEHOLDER;
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public delete(event: any) {
    event.stopPropagation();

    this.gamesService.removeGame(this.game!.Code);
  }

  public toggleFavorite(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.game?.Code) return;
    if (!this.isFavourite)
      this.gamesService.addGame(this.game?.Code, GameType.Favorite);
    else this.gamesService.removeGame(this.game?.Code);

    this.isFavourite = !this.isFavourite;
    if(this.isFavourite) this.sendGameSavedAsFavoriteEvent();
  }

  private sendGameSavedAsFavoriteEvent(): void {
    const {lcTotal, scTotal} = this.userInfoService.getBalanceTotals()
    this.analyticsService.trackEvent(AnalyticsEvent.GAME_SAVED_AS_FAVORITE, {
      game_source: this.source,
      game_category: this.category?.Title || null,
      game_collection: this.collection?.Title || null,
      game_id: this.game?.id || 0,
      sc_balance: scTotal,
      lc_balance: lcTotal,
    } as GameEventDataWithBalances)
  }

  public openGame(): void {
    // Send analytics event
    this.analyticsService.trackEvent(AnalyticsEvent.GAME_CLICKED, {
      game_source: this.source,
      game_category: this.category?.Title || null,
      game_collection: this.collection?.Title || null,
      game_id: this.game?.id || 0,
      game_position: this.gamePositionIndex + 1
    } as GameClickedEventData)

    let balance = 0;
    this.userInfoService
      .getCurrentBalance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(b => {
        balance = b === undefined ? 0 : b.balance + b.lockedBalance;
      });
    if (this.isAuthenticated && balance === 0) {
      this.modalService.open(FreeCoinsModalComponent);
    }
      this.navigationService.navigateToWithState(this.gameUrl, {
        source: this.source,
        category: this.category,
        collection: this.collection,
      });
    this.onLaunch.emit();
  }

  onImageLoaded() {
    this.isImageLoaded = true;
  }

  onInViewport(isInViewport: boolean): void {
    if (isInViewport && !this.wasInViewportOnce && this.game) {
      this.wasInViewportOnce = true;
      const eventData: GameBaseEventData = {
        game_category: this.category?.Title || null,
        game_collection: this.collection?.Title || null,
        game_id: this.game.id,
        game_source: this.source,
      }
      this.analyticsService.trackEvent(AnalyticsEvent.GAME_VIEWED, eventData)
    }
  }
}