import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {CheckPlatformService} from "../services";

@Directive({
    selector: '[appInViewport]'
})
export class InViewportDirective implements OnInit, OnDestroy {
    @Output() inViewport: EventEmitter<boolean> = new EventEmitter<boolean>();
    private observer!: IntersectionObserver;

    constructor(private el: ElementRef, private readonly checkPlatformService: CheckPlatformService,) {}

    ngOnInit(): void {
        if (this.checkPlatformService.checkIfIsPlatformBrowser()) {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    this.inViewport.emit(entry.isIntersecting);
                });
            }, {threshold: 0.2});

            this.observer.observe(this.el.nativeElement);
        }
    }

    ngOnDestroy(): void {
        if (this.checkPlatformService.checkIfIsPlatformBrowser()) {
            this.observer.disconnect();
        }
    }
}