import {Component, HostListener, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';

import {BehaviorSubject, combineLatest, distinct, Subject, switchMap} from "rxjs";
import {LobbyCategory, LobbyCollection, LobbyGame} from "../../../core/interfaces/lobby";
import {AnalyticsEvent, AnalyticsService, Breakpoint, CategoriesProvider, GameBaseEventData} from "../../../core";
import {debounceTime, filter, takeUntil, tap} from "rxjs/operators";

@Component({
    selector: 'app-category-carousel',
    templateUrl: './category-carousel.component.html',
    styleUrls: ['./category-carousel.component.scss']
})
export class CategoryCarouselComponent implements OnDestroy, OnInit, OnChanges {
    private destroy$ = new Subject<boolean>();
    private itemsPerLoad = new BehaviorSubject<number>(0);
    private currentPage = new BehaviorSubject<number>(0);
    private scrollTimeout: any | null = null;
    private loadingNextPage = false;

    @Input() category!: LobbyCategory;
    @Input() collection: LobbyCollection | null = null;
    @Input() vertical: boolean = false;

    public canGoPrev: boolean = false;
    public canGoNext: boolean = true;
    public isLoading: boolean = true;
    public take: number = 0;
    public skip: number = 0;
    public totalCount: number = 0;
    public itemsPerPage: number = 0;
    public games: LobbyGame[] = [];

    constructor(
        private readonly categoriesProvider: CategoriesProvider,
        private readonly analyticsService: AnalyticsService,
    ) {
    }

    get showAll() {
        return this.totalCount > this.itemsPerLoad.value
    }

    get canLoadMore() {
        return (this.totalCount / this.take) > (this.currentPage.value + 1);
    }

    ngOnChanges() {
        if (!this.category) return;

        this.onResize();
    }

    ngOnInit(): void {
        combineLatest([
            this.itemsPerLoad,
            this.currentPage
        ]).pipe(
            takeUntil(this.destroy$),
            debounceTime(200),
            filter(([itemsPerPage, currentPage]) => {
                return itemsPerPage > 0
            }),
            distinct(),
            tap(() => {
                this.isLoading = true;
            }),
            switchMap(([itemsPerPage, currentPage]) => {
                this.take = itemsPerPage;
                this.skip = currentPage * itemsPerPage;

                return this.categoriesProvider.getCategoryGames(this.category.Slug, this.take, this.skip)
            })
        ).subscribe((response) => {
            this.games.push(...response.items)

            this.isLoading = false;
            this.loadingNextPage = false;
            this.totalCount = response.totalCount;
        })
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    loadNext() {
        if (this.loadingNextPage || !this.canLoadMore) return;
        this.loadingNextPage = true;

        this.currentPage.next(this.currentPage.value + 1)
    }

    goNext($event: Event) {
        this.scrollTo($event, 'next');
    }

    goPrev($event: Event) {
        this.scrollTo($event, 'prev');
    }

    onScroll($event: Event) {
        if (this.isLoading) {
            return;
        }

        if (this.scrollTimeout)
            clearTimeout(this.scrollTimeout);

        this.scrollTimeout = setTimeout(() => {
            const gamesBlock: HTMLElement | null = ($event.target as HTMLElement).closest(".games-block");
            const container: HTMLElement | null = gamesBlock?.querySelector('.container') || null;
            const endLine: HTMLElement | null = container?.querySelector('#end-line') || null;
            const endLineWidthPosition = endLine?.getBoundingClientRect().x || 0;
            const shouldLoadMore = document.documentElement.offsetWidth * 2 > endLineWidthPosition;

            if (this.canLoadMore && shouldLoadMore) {
                this.loadNext();
            } else if (container) {
                const gameGapSize = 12;
                const isLast = (container.scrollLeft + container.offsetWidth + gameGapSize) > container.scrollWidth;
                this.canGoNext = !isLast;
            }

            this.canGoPrev = (container?.scrollLeft || 0) > 0;
        }, 100)
    }

    @HostListener('window:resize', ['$event'])
    private onResize(event?: Event): void {
        const itemsPerLoad = this.vertical ? 50 : 12;

        if (this.itemsPerLoad.value !== itemsPerLoad)
            this.itemsPerLoad.next(itemsPerLoad);

        this.itemsPerPage = 3;

        if (window.innerWidth > Breakpoint.tablet)
            this.itemsPerPage = 4
        if (window.innerWidth > Breakpoint.laptop)
            this.itemsPerPage = 5
        if (window.innerWidth > Breakpoint.desktop)
            this.itemsPerPage = 6
    }

    @HostListener("window:scroll", ["$event"])
    private onWindowScroll() {
        if (!this.vertical || !this.canLoadMore || this.isLoading || this.loadingNextPage) {
            return;
        }

        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let offsetHeight = scrollTop + document.documentElement.offsetHeight;
        let endLine = document.getElementById('end-line')
        let endLineHeight = (endLine?.offsetTop ?? 0) - offsetHeight

        if (endLineHeight < 0) {
            this.loadNext();
        }
    }

    private scrollTo($event: Event, direction: 'next' | 'prev') {
        const gamesBlock = ($event.target as Element).closest(".games-block");
        const container = gamesBlock?.querySelector('.container');
        const gameItem = gamesBlock?.querySelector('.game-item');

        const gameGapSize = 12;
        const gameItemWidth = gameItem!.clientWidth | 0;
        const scrollWidth = gameItemWidth * this.itemsPerPage + (gameGapSize * this.itemsPerPage);
        const scrollBy = direction == 'next' ? scrollWidth : -scrollWidth;

        container?.scrollBy(scrollBy, 0);
    }

    onClickShowAll() {
        this.analyticsService.trackEvent(AnalyticsEvent.SHOW_ALL_GAMES_CLICKED, {
            game_source: 'lobby',
            game_category: this.category.Title ?? null,
            game_collection: this.collection?.Title ?? null
        }as GameBaseEventData)
    }
}