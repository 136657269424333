<div class="navigation">
    <div class="navigation__group">
        <a *ngFor="let link of links"
           [class.active]="setActiveState(selectedLink, link.Url)"
           [attr.aria-label]="link.Title"
           (click)="change(link.Url)"
        >
            <span>{{ link.Title }}</span>
        </a>
        <a *ngIf="isAuthenticated"
           routerLinkActive="active"
           [attr.aria-label]="'My Games'"
           [routerLink]="'/games/my-games'"
           (click)="sendDisplayCollectionEvent('My Games')"
        >
            <span>My Games</span>
        </a>
    </div>
    <div class="navigation__search">
        <app-search-dropdown [dropdownPosition]="'right'"></app-search-dropdown>
    </div>
</div>

<section class="game-category">
    <article class="game-category__row" *ngFor="let category of categories; let i = index">
        <app-category-carousel [category]="category" [collection]="collection"></app-category-carousel>
    </article>
</section>