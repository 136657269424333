import { Injectable, isDevMode } from '@angular/core';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { environment } from '../../../environments/environment';
import {CoinPackageEventData, CoinPackageOffer} from "../interfaces";
import {UserInfoService} from "../store";
import { AnalyticsEvent } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private analytics;

    constructor(
        private readonly userInfoService: UserInfoService,
    ) {
        this.analytics = Analytics({
            app: 'sweeptastic',
            debug: true,
            plugins: [
                googleTagManager({
                    containerId: environment.GOOGLE_KEY,
                }),
            ],
        });
    }

    trackPageView() {
        this.analytics.page({
            event: 'page_view'
        });
    }

    trackEvent(eventName: AnalyticsEvent, eventData?: Record<string, any>) {
        if(isDevMode()) console.log('trackEvent > ', {eventName, eventData});
        this.analytics.track(eventName, eventData);
    }

    identify(userId: string, userData: Record<string, any>) {
        this.analytics.identify(userId, userData);
    }

    mapCoinPackageEventData(coinPackage: CoinPackageOffer, packagePosition = 0):  CoinPackageEventData{
        const {lcTotal, scTotal} = this.userInfoService.getBalanceTotals()
        // convert prices to number and set to 0 if null
        const ogPrice = coinPackage.price;
        const discountedPrice = coinPackage.discountPrice ?? 0; // discountPrice is actually already discounted
        const effectivePrice = discountedPrice ?? ogPrice;
        return {
            package_id: coinPackage.id,
            package_position: packagePosition,
            is_free_package: !Boolean(ogPrice), // price parameters are null if it's true
            is_claim_once: coinPackage.isClaimedOnce,
            has_discount: Boolean(coinPackage.discountPrice),
            has_label: Boolean(coinPackage.labelName),
            has_timeout: Boolean(coinPackage.expirationTime && coinPackage.limitedTimeOffer),
            original_price: ogPrice,
            discounted_price: discountedPrice, // define which is going to be the price paid by the user
            effective_price: effectivePrice, // Actual price paid by the user, discounted price
            lc_amount: coinPackage.goldCoins, // LC amount on the package
            sc_amount: coinPackage.sweepCoins, // SC amount on the package
            label: coinPackage.labelName, // validate with Martin null if empty
            label_color: coinPackage.labelColor, // specify the CSS attribute to add here
            timeout: this.getCoinTimeout(coinPackage), // null in case is empty
            sc_balance: scTotal,
            lc_balance: lcTotal
        }
    }

    private getCoinTimeout(coin: CoinPackageOffer | null): string | null {
        if (!coin?.expirationTime) return null;

        const now = new Date();
        const expirationDate = new Date(coin.expirationTime);

        if (expirationDate > now) {
            const timeDifference = expirationDate.getTime() - now.getTime();

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

            return `${days}d ${hours}h ${minutes}m`;
        } else {
            return 'Expired';
        }
    }

}