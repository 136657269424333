<div class="payment-container">
    <ng-container *ngIf="showButton && !isFormOpen">
        <button class="btn btn-extra-large btn-orange" (click)="initPayment()">
            <i class="u_bitcoin ml-14"></i>
            <span>Cryptocurrencies</span>
        </button>
    </ng-container>
    <ng-container *ngIf="isFormOpen">
        <div class="payment-title">
            Pay with Cryptocurrencies:
        </div>
        <form [formGroup]="form" class="form-container">
            <ng-container *ngLet="form.get('currency') as formInput">
                <mat-form-field
                        *ngIf="formInput"
                        appearance="outline"
                        class="material-block material-block__select material-block__select-gray"
                        hideRequiredMarker="true"
                >
                    <mat-label>Currency</mat-label>
                    <mat-select formControlName="currency" trackFormField fieldName="currency" [formId]="formId" [control]="form.get('currency')">
                        <mat-option *ngFor="let currency of currencies" [value]="currency.value">
                            {{currency.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formInput.invalid && formInput.touched">{{getErrorMessage(formInput)}}</mat-error>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="paymentInvoice">
                <div class="invoice-refresh">
                    <span class="invoice-refresh__button">refresh in {{refreshSeconds}} seconds</span>
                </div>
                <div class="payment-invoice" >
                    <div class="payment-invoice__item accent">
                        <span>Invoice amount:</span>
                        <span>{{paymentInvoice.invoice_amount}} {{paymentInvoice.invoice_currency}}</span>
                    </div>
                    <div class="payment-invoice__item mb-16 mt-16">
                        <span>{{getInvoiceCurrencyTitle(paymentInvoice)}}:</span>
                        <span>1 {{paymentInvoice.currency}}
                            = {{paymentInvoice.rate}} {{paymentInvoice.invoice_currency}}</span>
                    </div>
                    <div class="payment-invoice__item">
                        <span>Exchange amount:</span>
                        <span>{{paymentInvoice.amount_exchange}} {{paymentInvoice.currency}}</span>
                    </div>
                    <div class="payment-invoice__item">
                        <span>Network processing fee:</span>
                        <span>{{paymentInvoice.network_processing_fee}} {{paymentInvoice.currency}}</span>
                    </div>
                    <div class="payment-invoice__item mb-16 accent">
                        <span>Total:</span>
                        <span>{{paymentInvoice.amount}} {{paymentInvoice.currency}}</span>
                    </div>
                    <div class="payment-invoice__item faded">
                        <span>Expected time to wait:</span>
                        <span>{{paymentInvoice.wait_time}}</span>
                    </div>
                    <div class="payment-invoice__item faded">
                        <span>TX fee ({{paymentInvoice.fast_transaction_fee_currency}}) set to:</span>
                        <span>{{paymentInvoice.fast_transaction_fee}}</span>
                    </div>
                </div>
            </ng-container>
            <button class="btn btn-block btn-extra-large btn-green mb-6" [disabled]="!canSubmit" (click)="startPayment()">
                <span>Pay</span>
            </button>
            <button class="btn btn-block btn-secondary" (click)="close()">
                <span>Cancel</span>
            </button>
        </form>
    </ng-container>
</div>
<div class="modal-container" *ngIf="inProgress">
    <div class="modal-backdrop"></div>
    <div class="modal">
        <div class="modal__header">
            <h1>Checkout</h1>
            <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
        </div>
        <div class="modal__content" *ngIf="iframeUrl">
            <iframe [src]="iframeUrl"></iframe>
        </div>
    </div>
</div>