import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {AnalyticsStore, AnalyticsStateEntity} from "./analytics.store";

@Injectable({providedIn: 'root'})
export class AnalyticsQuery extends Query<AnalyticsStateEntity> {
  public buyingCoinPackage$ = this.select(item => item.buyingCoinPackage);

  constructor(
    private readonly _store: AnalyticsStore
  ) {
    super(_store);
  }
}